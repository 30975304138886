import type { AppProps } from 'next/app';
import { QueryClient, QueryClientProvider } from 'react-query';
import { NextPage } from 'next';
import { ReactElement, ReactNode } from 'react';
import { SidebarDrawerProvider } from 'contexts/SidebarDrawerContext';
import { ReactQueryDevtools } from 'react-query/devtools';
import DevTools from '../DevTools';
import AdvisorOverrideProvider from '../AdvisorOverrideProvider';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = Pick<AppProps, 'pageProps'> & {
  Component: NextPageWithLayout;
  queryClient: QueryClient;
};

const ReactQueryApp = ({
  Component,
  pageProps,
  queryClient,
}: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page: ReactElement) => page);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AdvisorOverrideProvider>
        <SidebarDrawerProvider>
          {getLayout(<Component {...pageProps} />)}
        </SidebarDrawerProvider>
      </AdvisorOverrideProvider>
      {process.env.NEXT_PUBLIC_STAGING === 'true' && <DevTools />}
    </QueryClientProvider>
  );
};
export default ReactQueryApp;
